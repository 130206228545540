<template>
  <div id="ingredientsView">
    <MultiButtonHero
      v-if="multiButtonHeroData"
      :multi-button-hero-data="multiButtonHeroData"
      @loaded="multiButtonHeroLoaded = true"
    />
    <IngredientsList
      v-if="ingredientsListData"
      :ingredients-list-data="ingredientsListData"
      @loaded="ingredientsListLoaded = true"
    />
  </div>
</template>

<script>
import api from '@/api'
import { mapMutations, mapState } from 'vuex'
import MultiButtonHero from '@/components/Hero/MultiButtonHero'
import IngredientsList from '@/components/Ingredients/IngredientsList'
import helpers from '@/api/helpers/helpers'
export default {
  name: 'Ingredients',
  components: { IngredientsList, MultiButtonHero },
  data() {
    return {
      multiButtonHeroData: null,
      ingredientsListData: null,
      multiButtonHeroLoaded: false,
      ingredientsListLoaded: false,
    }
  },
  computed: {
    ...mapState({
      pageLoading: (state) => state.pageLoading,
    }),
  },
  watch: {
    multiButtonHeroLoaded: {
      handler(newVal) {
        if (!newVal) return
        this.updatePageLoading()
      },
    },
    ingredientsListLoaded: {
      handler(newVal) {
        if (!newVal) return
        this.updatePageLoading()
      },
    },
  },
  beforeMount() {
    this.loadIngredientsView()
  },
  methods: {
    ...mapMutations({
      setPageLoadingFalse: 'setPageLoadingFalse',
    }),
    loadIngredientsView() {
      this.setIngredientsData(helpers.readStoredResponse('view.ingredients'))

      api.views.loadIngredients().then((response) => {
        this.setIngredientsData(response)
      })
    },
    setIngredientsData(response) {
      if (!response || !response.entry) return

      this.multiButtonHeroData = response.entry.multiButtonHero[0]
      this.ingredientsListData = response.entry.ingredientsList[0]
    },
    updatePageLoading() {
      if (this.multiButtonHeroLoaded && this.ingredientsListLoaded) {
        setTimeout(() => {
          this.setPageLoadingFalse()
        }, 300)
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
