<template>
  <div
    class="multi-button-hero-container"
    v-observe-visibility="{
      callback: visibilityChanged,
      once: true,
      throttle: 600,
      intersection: {
        threshold: 0.3,
      },
    }"
  >
    <div class="background-image">
      <img
        :src="multiButtonHeroData.backgroundImage[0].url"
        :alt="multiButtonHeroData.backgroundImage[0].text"
      />
      <div class="overlay"></div>
    </div>

    <div class="container">
      <div class="button-container">
        <div class="button-list">
          <div
            class="button-link-container"
            v-for="button in multiButtonHeroData.buttons"
            :key="button.id"
          >
            <AnchorLink
              class="button"
              type="secondaryButton"
              :anchor-link="button.buttonLink"
              :new-tab="button.newTab"
            >
              {{ button.buttonText }}
            </AnchorLink>
          </div>
        </div>
      </div>

      <div class="content">
        <h3>{{ multiButtonHeroData.subtitle }}</h3>
      </div>
    </div>
  </div>
</template>

<script>
import AnchorLink from '@/components/UI/AnchorLink'
export default {
  name: 'MultiButtonHero',
  components: { AnchorLink },
  props: {
    multiButtonHeroData: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.preloadImage()
  },
  methods: {
    preloadImage() {
      const image = document.createElement('img')

      image.onload = () => {
        this.$emit('loaded')
      }

      image.src = this.multiButtonHeroData.backgroundImage[0].url
    },
    visibilityChanged(isVisible, entry) {
      if (!isVisible) return
      entry.target.classList.add('showing')
    },
  },
}
</script>

<style lang="scss" scoped>
.multi-button-hero-container {
  @apply relative;

  &.showing {
    .background-image {
      .overlay {
        @apply opacity-100;
      }
    }

    .container {
      .button-container {
        .button-list {
          .button-link-container {
            @apply opacity-100 left-0;
          }
        }
      }

      .content {
        @apply opacity-100;
      }
    }
  }

  .background-image {
    @apply relative w-full;

    img {
      @apply min-w-full max-w-none object-cover;
      max-height: 700px;
      z-index: 1;
      margin-left: 50%;
      transform: translateX(-50%);
    }

    .overlay {
      @apply w-full h-full absolute top-0 opacity-0;
      background: rgba(32, 44, 57, 0.7);
      overflow: hidden;
      z-index: 1;
      transition: all 0.7s ease-in;
    }
  }

  .container {
    @apply absolute top-0 left-0 right-0 text-white w-full h-full flex flex-col-reverse items-center justify-center;
    z-index: 2;

    .button-container {
      @apply w-full;

      .button-list {
        @apply w-full flex flex-wrap justify-evenly;

        .button-link-container {
          @apply relative inline-block w-full opacity-0;
          left: -1em;

          @for $i from 1 through 20 {
            &:nth-child(#{$i}n) {
              transition: all 0.7s ease-in #{0.7 + ($i * 0.4)}s;
            }
          }

          .button {
            @apply my-2 inline-block;
          }
        }
      }
    }

    .content {
      @apply w-full px-6 pb-12 opacity-0;
      font-family: 'Inter Medium', sans-serif;
      font-size: 1.3em;
      transition: all 1s ease-in 1.7s;
    }
  }

  @media screen and (min-width: 600px) {
    .container {
      .button-container {
        .button-list {
          .button-link-container {
            @apply w-1/2;
          }
        }
      }

      .content {
        font-size: 1.5em;
      }
    }
  }

  @media screen and (min-width: 900px) {
    .container {
      @apply flex-row;

      .button-container {
        @apply w-auto;

        .button-list {
          @apply inline-block;

          .button-link-container {
            @apply block w-auto;

            .button {
              @apply block;
            }
          }
        }
      }

      .content {
        @apply w-3/5 pb-0;
        font-size: 1.7em;
      }
    }
  }

  @media screen and (min-width: 1200px) {
    .container {
      .content {
        font-size: 1.9em;
      }
    }
  }
}
</style>
