<template>
  <div class="ingredients-list-container">
    <div class="container">
      <div class="content" v-html="ingredientsListData.description"></div>

      <div class="ingredients-list">
        <AnchorLink
          class="ingredient"
          v-for="ingredient in ingredientsListData.ingredients"
          :key="ingredient.id"
          :anchor-link="'/' + ingredient.uri"
        >
          <div class="image-container">
            <img
              :src="ingredient.ingredientTile[0].tallTileImage[0].url"
              :alt="ingredient.ingredientTile[0].tallTileImage[0].title"
            />
            <div class="overlay"></div>
          </div>

          <div class="content-container">
            <h4>{{ ingredient.ingredientTile[0].titleText }}</h4>

            <AnchorLink
              type="secondaryButton"
              :anchor-link="'/' + ingredient.uri"
            >
              More Info
            </AnchorLink>
          </div>
        </AnchorLink>
      </div>
    </div>
  </div>
</template>

<script>
import AnchorLink from '@/components/UI/AnchorLink'
export default {
  name: 'IngredientsList',
  components: { AnchorLink },
  props: {
    ingredientsListData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      itemsLoaded: [],
    }
  },
  watch: {
    itemsLoaded: {
      handler(val) {
        if (val.length === this.ingredientsListData.ingredients.length) {
          this.$emit('loaded')
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.preloadImages()
  },
  methods: {
    preloadImages() {
      this.ingredientsListData.ingredients.forEach((ingredient) => {
        let image = document.createElement('img')

        image.onload = () => {
          this.itemsLoaded.push(true)
        }

        image.src = ingredient.ingredientTile[0].tallTileImage[0].url
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.ingredients-list-container {
  @apply pt-20 pb-20;

  .container {
    @apply flex flex-col justify-center items-center;
    .content {
      @apply w-11/12 mb-20;
      font-family: 'Inter Medium', sans-serif;
      font-size: 1.2em;
      color: #193153;
      letter-spacing: 1px;
    }

    .ingredients-list {
      @apply flex justify-center flex-wrap;

      .ingredient {
        @apply relative w-full flex flex-col justify-center items-center px-4 mb-12;

        &:hover {
          .image-container {
            img {
              transform: scale(1.1);
            }
          }
        }

        .image-container {
          @apply relative overflow-hidden;

          img {
            border-radius: 10px;
            transition: all 0.5s ease-in;
          }

          .overlay {
            @apply w-full h-full absolute top-0;
            background: rgba(32, 44, 57, 0.6);
            overflow: hidden;
            z-index: 1;
            border-radius: 10px;
          }
        }

        .content-container {
          @apply absolute top-0 text-white w-1/2 h-full flex flex-col items-center justify-center;
          z-index: 2;

          h4 {
            @apply mb-10;
            font-family: 'Inter SemiBold', sans-serif;
            font-size: 1.5em;
            line-height: 1.2em;
          }
        }
      }
    }
  }

  @media screen and (min-width: 600px) {
    .container {
      .content {
        @apply w-4/5;
        font-size: 1.3em;
      }

      .ingredients-list {
        .ingredient {
          @apply w-1/2;
        }
      }
    }
  }

  @media screen and (min-width: 900px) {
    @apply pt-24 pb-28;

    .container {
      .content {
        @apply w-4/5;
        font-size: 1.35em;
      }

      .ingredients-list {
        @apply flex-nowrap;

        .ingredient {
          @apply w-1/3;

          .content-container {
            @apply w-2/3;
          }
        }
      }
    }
  }

  @media screen and (min-width: 1200px) {
    @apply pt-32;

    .container {
      .content {
        @apply w-3/5;
        font-size: 1.5em;
      }

      .ingredients-list {
        .ingredient {
          .content-container {
            @apply w-1/2;
          }
        }
      }
    }
  }
}
</style>
